<template>
  <div class="card card-body shadow-sm">
    <app-basic-table
      ref="basic_table"
      :table-name="$t('users.user list')"
      :filters.sync="filters"
      :setting-columns="columns"
      :endpoint-list="ENDPOINT.BEACON_HISTORY_LIST($route.params.id)"
      @resetAllSearch="onResetAllSearch"
      @searched="onSearch"
    >
      <template v-slot:filters>
        <div class="row mt-3">
          <div class="col-xl-4 col-lg-6 col-sm-6">
            <div class="form-group row">
              <div class="col-xl-12 col-lg-12 col-sm-12  mt-0">
                <label class="align-self-center mb-0 mr-5">{{ filters.date_equal.label }}</label>
              </div>
              <div class="col-xl-12 col-lg-12 col-sm-12  mt-3">
                <app-date-range-picker
                  :name="filters.date_equal.name"
                  v-model="filters.date_equal.value"
                  timePicker24Hour="false"
                  format="YYYY-MM-DD"
                  @input="filters.date_equal.value = $event"
                />
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:body-cell-apartment_id="props">
        <td class="app-align-middle">
          <p :data-original-title="getApartmentName(props.row.apartment_id)" style="color: #007bff;"
             @click="handlerApartmentNameClick(props.row)" class="apartment_name app-table-p app-cell-tooltip mb-0">
            {{props.row.apartment_name}}
          </p>
        </td>
      </template>

      <template v-slot:body-cell-id="props">
        <td class="app-align-middle">
          <p class="app-table-p app-cell-tooltip mb-0" style="text-align: center;vertical-align: middle">
            {{props.row.id}}
          </p>
        </td>
      </template>

      <template v-slot:body-cell-rssi="props">
        <td class="app-align-middle">
          <p class="app-table-p app-cell-tooltip mb-0" style="text-align: center;vertical-align: middle">
            {{props.row.rssi + "dBm"}}
          </p>
        </td>
      </template>

      <template v-slot:table-menu-right>
        <button @click="handleBtnBackClick()" class="btn btn-default">
          {{ $t("common.back") }}
        </button>
      </template>
    </app-basic-table>
  </div>
</template>

<script>
  import AppBasicTable from "@components/_common/list/AppBasicTable";
  import AppDateRangePicker from "../../../components/forms/AppDateRangepicker";
  import {ENDPOINT} from "../../../constants/api";
  import {FACILITIES_CONSTANT} from "../../../constants/facilities";
  import {BEACONS_CONSTANT} from "../../../constants/beacons";

  export default {
    name: "FacilityList",
    components: {
      "app-basic-table": AppBasicTable,
      AppDateRangePicker,
    },
    data() {
      return {
        idCache: null,
        paramGetList: {},
        filters: {
          date_equal: {
            name: "date",
            condition: "equal",
            label: this.$t("beacons.date")
          },
        },
        columns: [
          {name: "id", label: this.$t("common.id"), sortable: true, textAlign: 'text-center'},
          {name: "beacon.name", label: this.$t("beacons.name"), sortable: true},
          {name: "beacon_id", label: this.$t("beacons.serial")},
          {name: "timestamp", label: this.$t("beacons.date_get_data"), sortable: true, textAlign: 'text-center'},
          {
            name: "gateway_id",
            label: this.$t("beacons.gateway"),
            sortable: true,
            textAlign: 'text-center'
          },
          {name: "rssi", label: this.$t("beacons.signal"), sortable: true, textAlign: 'text-center'},
          {name: "report_type", label: this.$t("beacons.report"), textAlign: 'text-center'},
          {name: "package_data", label: this.$t("beacons.data_package"), textAlign: 'text-center'},

        ],
        meta: {
          apartments: [],
        },
        endPoint: ''
      };
    },
    watch: {},
    methods: {

      async handleBtnBackClick() {
        await this.$router.push({
          name: this.ROUTES.ADMIN.BEACONS_LIST,
          query : {
            'filters.status_enabled.equal': 'all',
            'filters.apartment_id.equal' : 'all'
          }
        });
      },
      handleBtnUpdateClick(entry) {
        this.$router.push({name: this.ROUTES.ADMIN.BEACONS_EDIT, params: {id: entry.id}})
      },
      handleBtnHistoryClick(entry) {
        this.$router.push({name: this.ROUTES.ADMIN.BEACONS_EDIT, params: {id: entry.id}})
      },
      handleBtnManageStatus(entry) {
        this.$router.push({name: this.ROUTES.ADMIN.FACILITY_MANAGE_STATUS, params: {id: entry.id}})
      },
      handleBtnPlanList(entry) {
        this.$router.push({name: this.ROUTES.ADMIN.FACILITY_PLAN_LIST, params: {facility_id: entry.id}})
      },
      handlerApartmentNameClick(entry) {
        this.$router.push({name: this.ROUTES.ADMIN.APARTMENT_EDIT, params: {id: entry.apartment_id}})
      },

      getStatusEnabled(status) {
        let data = BEACONS_CONSTANT.STATUS.find(({id}) => id === status);
        return data.name
      },
      getColorStatusDispCrowded(status) {
        let data = FACILITIES_CONSTANT.disp_crowed.find(({id}) => id === status);
        return data.color
      },
      onResetAllSearch() {
        this.$router.push({name: this.ROUTES.ADMIN.BEACONS_HISTORY, params: {id: this.route.params.id} , query : {
            'filters.date.equal' : moment().format('YYYY-MM-DD')
        }})
      },
      onSearch() {
        // this.getTourSelectName();
      },
      getApartmentName(apartmentId) {
        let apartment = this.meta.apartments.find(({id}) => id === apartmentId);
        if (apartment == undefined) {
          return '';
        }
        return apartment.name;
      },
    },
    mounted: function () {
      // this.getParamsFilters({})

      this.$request.get(this.ENDPOINT.UI_HELPER_CUSTOMER_MEALS).then(res => {
        this.meta = {
          apartments: res.data.apartments,
        }
        this.meta.apartments.unshift({
          id : "all",
          name : "全て"
        })
      })
    },
  }
</script>

<style scoped>
  table tr td {
    padding: 16.6px .75rem !important;
  }
</style>
